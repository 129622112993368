.application__card {
  width: 350px;
  background-color: white;
  transition: all 0.2s ease;
  margin-bottom: 50px;
  border-bottom: 4px solid var(--secondary-color);
}
.application__card:hover {
  transition: all 0.2s ease;
  box-shadow: 0px 10px 50px 0px #88888850;
}
.application__card__image__block {
}

.application__card__image {
  width: 350px;
  height: 298px;
}
.application__card__title__block {
  background-color: white;
  /* width: 100%; */
  padding: 0px 15px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.application__card__title {
  margin: 20px 0px;
  width: 70%;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-color);
}

@media only screen and (max-width: 1200px) {
  .application__card {
    /* width: 280px; */
    margin-bottom: 40px;
  }

  .application__card__title {
    margin: 8px 0px;
    /* width: 70%; */
    font-size: 22px;
  }
}
@media only screen and (max-width: 991px) {
  .application__card {
    width: 280px;
    margin-bottom: 40px;
  }

  .application__card__image {
    width: 280px;
    height: 239px;
  }
  .application__card__title__block {
    min-height: 60px;
  }
  .application__card__title {
    margin: 10px 0px;
    width: 70%;
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .application__card {
    width: 240px !important;
    margin-bottom: 30px;
  }

  .application__card__image {
    width: 240px !important;
    height: 204px !important;
  }
  .application__card__title__block {
    min-height: 20px;
  }
  .application__card__title {
    width: 60%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 578px) {
  .application__card {
    width: 200px !important;
    overflow: hidden;
    margin-bottom: 20px;
    border-bottom: 3px solid var(--secondary-color);
  }

  .application__card__image {
    width: 200px !important;
    height: 170px !important;
  }
  .application__card__title__block {
    min-height: 20px;
  }
  .application__card__title {
    width: 80%;
    font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  .application__card {
    width: 160px !important;
    overflow: hidden;
    border-bottom: 2px solid var(--secondary-color);
  }

  .application__card__image {
    width: 160px !important;
    height: 136px !important;
  }
  .application__card__title__block {
    min-height: 20px;
  }
  .application__card__title {
    width: 100%;
    font-size: 14px;
  }
}
@media only screen and (max-width: 400px) {
  .application__card {
    width: 140px !important;
    overflow: hidden;
  }

  .application__card__image {
    width: 140px !important;
    height: 119px !important;
  }
  .application__card__title__block {
    min-height: 20px;
  }
  .application__card__title {
    width: 100%;
    font-size: 12px;
  }
}
@media only screen and (max-width: 340px) {
  .application__card {
    width: 120px !important;
    overflow: hidden;
  }

  .application__card__image {
    width: 120px !important;
    height: 102px !important;
  }
  .application__card__title__block {
    min-height: 20px;
  }
  .application__card__title {
    width: 100%;
    font-size: 10px;
  }
}
/* @media only screen and (max-width: 480px) {
  .application__card {
    width: 100% !important;
  }
 
} */
