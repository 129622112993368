.navbar__block{
background-color: white;
}
.inner__navbar__block{
    padding: 5px 15px;
    background-color: white;
}
.navbar-brand {
    margin: 0px;
}
.navbar{
    padding: 0px;
    background-color: white;
}
.nav-link{
    color: var(--primary-color) !important;
    font-size: 16px;
    font-weight: 500;
    margin-right: 15px;
}
.nav-link:hover{
    color: var(--secondary-color) !important;
}
.nav-link:active{
    color: var(--secondary-color) !important; 
}
.nav-link:focus{
    color: var(--secondary-color) !important; 
}
.logout__button{
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background: none;
   color: var(--primary-color);
    border: none;
    outline: none;
}
/* .navbar-toggler{
    color: white;

    background-color: var(--primary-color);
} */
/* .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .custom-toggler.navbar-toggler {
    border-color: rgb(255,102,203);
  }  */