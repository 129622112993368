.contact__map__block {
  width: 100%;
  height: 350px;
}
@media only screen and (max-width: 578px) {
  .contact__map__block {
    height: 250px;
  }
}

.contact__page__title__block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact__page__title {
  color: var(--secondary-color);
  margin: 32px 0px 10px;
  font-size: 36px;
  text-align: center;
  font-family: poppins;
  /* font-style: italic; */
  font-weight: 500;
}

.contact__page__description {
  margin: 0px auto;
  width: 80%;
  font-size: 16px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .contact__page__title {
    font-size: 26px;
    margin: 20px 0px 10px;
  }
}
@media only screen and (max-width: 768px) {
  .contact__page__title {
    font-size: 24px;
    margin: 20px 0px 10px;
  }
  .contact__page__description {
    width: 100%;
  }
}
@media only screen and (max-width: 578px) {
  .contact__page__title {
    font-size: 24px;
    margin: 20px 0px 10px;
  }
}

.contact__address__block {
  padding: 20px !important;
}
.inner__contact__address__block{
  padding: 20px !important;
}
.contact__form__block {
  width: 80%;
  margin: 0 auto;
  padding: 0px 15px 0px 0px;
}

.inner__contact__form__block {
  padding: 15px;
  box-shadow: 0px 0px 20px 0px #88888835;
}

@media only screen and (max-width: 768px) {
  .contact__address__block {
    padding: 20px !important;
  }
  .contact__form__block {
    padding: 0px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 568px) {
  .contact__form__block {
    width: 100% !important;
    padding: 0px;
    margin: 0px 0px 15px 0px;
  }
}

/* 
.contact__form__block {

  padding: 0px 15px 0px 0px;
}

.inner__contact__form__block {
  padding: 15px;
  box-shadow: 0px 0px 20px 0px #88888835;
}

@media only screen and (max-width: 768px) {
  .contact__form__block {
    padding: 0px;
    margin-bottom: 15px;
  }
} */

.form__field__block {
  padding: 0px 15px;
}
.form__field {
  padding: 0px;
  margin-bottom: 15px;
}
.left__form__field {
  padding-right: 8px;
}
.right__form__field {
  padding-left: 8px;
}
.input__field {
  width: 100%;
  padding: 20px;
  border: 0px solid;
  background-color: #f6f6f6;
  border: 0px solid;
}
.input__field:hover {
  background-color: #f6f6f6;
  border: 0px solid;
}
.input__field:focus {
  background-color: #f6f6f6;
  outline: none;
  border: 0px solid;
}
.input__field:visited {
  background-color: #f6f6f6;
  outline: none;
  border: 0px solid;
}
.career__page__label {
  margin: 0px;
}
.file__upload__field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
/* input[type='file'] {
  color: transparent;
  width: 250px;
} */
@media only screen and (max-width: 768px) {
  .input__field {
    font-size: 14px;
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 578px) {
  .input__field {
    font-size: 14px;
    padding: 10px 20px;
  }
  .left__form__field {
    padding-right: 0px;
  }
  .right__form__field {
    padding-left: 0px;
  }
}

.textarea {
  margin-bottom: 5px;
}
.form__field__button {
  padding: 0px;
}
.submit__button {
  padding: 10px 40px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  outline: none;
  font-size: 18px;
}
.submittting__button {
  opacity: 0.7;
}
.contact__page__address__block {
  padding: 15px;
  box-shadow: 0px 0px 20px 0px #88888835;
}
@media only screen and (max-width: 768px) {
  .form__field__button {
    width: 100%;
    margin: 0px auto;
  }
  .submit__button {
    font-size: 14px;
    padding: 10px 40px;
    margin: 0px auto;
  }
}
