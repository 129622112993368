.footer__contact__block {
  background-image: url("./../assets//Footer/footer__image.jpg");
  min-height: 140px;
}

.inner__footer__contact__block {
  min-height: 140px;
  background-color: #023698ea;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__contact__title__block {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px auto;
}
.footer__contact__title {
  margin: 0px;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.footer__contact__button {
  padding: 10px 30px;

  background-color: white;
  color: var(--primary-color);
  border-radius: 0px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 20px;
}
@media only screen and (max-width: 578px) {
  .footer__contact__block {
    height: 180px;
  }
  .inner__footer__contact__block {
    height: 180px;
  }
  .footer__contact__title__block {
    flex-direction: column;
    align-items: center;
  }
  .footer__contact__title {
    margin: 15px 0px 0px 0px;
    text-align: center;
  }
  .footer__contact__button {
    margin: 25px 0px 15px 0px;
    padding: 10px 45px;
  }
}

.inner__location__block {
  width: 90%;
  margin: 50px auto;
}
.address__block {
  padding: 0px;
}
.address__title__block {
  display: flex;
  align-items: center;
  /* justify-content: spa; */
}
.address__title {
  color: var(--secondary-color);
  font-size: 18px;
  font-weight: 500;
  margin: 0px 0px 0px 20px;
}
.full__address__text {
  margin-top: 20px;
  margin-bottom: 10px;
}
.footer__contact__tab__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__contact__tab {
  margin-bottom: 5px;
  color: var(--forth-color);
}
.footer__contact__tab a {
  color: var(--forth-color);
}
.footer__tab__icon {
  margin-right: 8px;
}

.marker {
  background-image: url("./../assets/icon/pin.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.map__block {
  height: 263px;
}
@media only screen and (max-width: 1200px) {
  .full__address__text {
    width: 80%;
  }
  .map__block {
    height: 287px;
  }
}
@media only screen and (max-width: 992px) {
  .inner__location__block {
    width: 100%;
    /* margin: 100px auto; */
  }
  .address__block {
    padding-right: 20px;
  }
  .full__address__text {
    width: 100%;
  }
  .map__block {
    height: 287px;
  }
}
@media only screen and (max-width: 768px) {
  .full__address__text {
    width: 100%;
  }
  .map__block {
    margin: 20px auto;
    width: 100%;
    height: 234px;
  }
}
.footer__block {
  background-color: var(--tertiory-color);
  padding: 30px 15px;
}
.inner__footer__block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.footer__left__side,
.footer__right__side {
  display: flex;
  align-items: flex-end;
}
.footer__logo {
  margin: 0px 15px 5px 0px;

}
.footer__link{
    color: var(--primary-color);
    margin: 0px 0px 0px 15px;
}
.footer__contact__link {
  margin: 0px 0px 0px 15px;
}
.footer__contact__link a {
  color: black;
}

@media only screen and (max-width: 1200px) {
  .inner__footer__block {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__left__side   {
    margin-bottom: 15px;
  }
  .footer__link__block{
    margin: 15px 0px 0px;
}

.footer__contact__link{
    margin: 0px 15px 0px 0px;
}
}
@media only screen and (max-width: 992px) {
  .inner__footer__block {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__left__side,
  .footer__right__side {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .footer__link__block{
      margin: 15px 0px 0px 0px;
  }
  .footer__link{
      margin:0px 15px 0px 0px;
  }
  .footer__contact__link{
      margin: 0px 15px 0px 0px;
  }
}
@media only screen and (max-width: 768px) {
.footer__link__block{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
}

.copyright__block {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.copright__text {
  font-size: 14px;
  margin: 0px;
}
.empty__copyright__text{
    display: block;
}
@media only screen and (max-width: 568px) {
    .copyright__block{
        display: block;
        position: relative;
        min-height: 60px;
    }
    .copright__text{
        position: absolute;
        left: 15px;
        bottom: 10px;
    }
    .empty__copyright__text{
        display: none;
    }
    }