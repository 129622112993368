* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --primary-color: #023798;
  --secondary-color: #fe1213;
  --tertiory-color: #f2f7ff;
  --fourth-color: #303030;

  --clr-primary-1: hsl(21, 91%, 17%);
  --clr-primary-2: hsl(21, 84%, 25%);
  --clr-primary-3: hsl(21, 81%, 29%);
  --clr-primary-4: hsl(21, 77%, 34%);
  --clr-primary-5: hsl(21, 62%, 45%);
  --clr-primary-6: hsl(21, 57%, 50%);
  --clr-primary-7: hsl(21, 65%, 59%);
  --clr-primary-8: hsl(21, 80%, 74%);
  --clr-primary-9: hsl(21, 94%, 87%);
  --clr-primary-10: hsl(21, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
.section__title {
  width: 80%;
  text-align: center;
  font-weight: 500;
  margin: 50px 0px;
  font-size: 32px;
  color: var(--secondary-color);
}
@media only screen and (max-width: 992px) {
  .section__title {
    font-size: 26px;
    margin: 40px 0px;
  }
}
@media only screen and (max-width: 768px) {
  .section__title {
    font-size: 24px;
    margin: 40px 0px;
  }
}
@media only screen and (max-width: 578px) {
  .section__title {
    font-size: 24px;
    margin: 30px 0px;
  }
}

.page__banner {
  width: 100%;
}


.section{
  margin-top: 40px;
}
@media only screen and (max-width: 1200px) {
  .section {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 992px) {
  .section {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .section {
    margin-top: 24px;
  }
}
@media only screen and (max-width: 578px) {
  .section {
    margin-top: 24px;
  }
}

.page__section__title {
  font-weight: 500;
  margin: 0px 0px 20px;
  font-size: 32px;
  color: var(--secondary-color);
}
@media only screen and (max-width: 992px) {
  .page__section__title {
    font-size: 26px;
    margin: 0px 0px 10px;
  }
}
@media only screen and (max-width: 768px) {
  .page__section__title {
    font-size: 24px;
    margin: 0px 0px 10px;
  }
}
@media only screen and (max-width: 578px) {
  .page__section__title {
    font-size: 24px;
    margin: 0px 0px 10px;

  }
}