.single__product {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px 0px #88888835;
}
.product__card__image__block {
  padding: 0px;
}
.product__car__image {
  padding: 0px;
  width: 100%;
}
.product__car__details {
  padding-left: 30px;
}
.product__card__title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 500;
  margin: 0px 0px 10px 0px;
}
.product__card__description {
  font-size: 16px;
  margin: 0px;
}
@media only screen and (max-width: 1200px) {
  .product__card__title {
    font-size: 18px;
    margin: 0px 0px 6px 0px;
  }
  .product__card__description {
    font-size: 14px;
  }
}
@media only screen and (max-width: 992px) {
  .product__card__image__block {
    overflow: hidden;
  }
  .product__car__image {
    padding: 0px;
    height: 241px;
    width: auto;
  }
}
@media only screen and (max-width: 768px) {
  .product__card__image__block {
    overflow: hidden;
  }
  .product__car__image {
    padding: 0px;
    width: 100%;
    height: auto;
  }
  .product__car__details {
    padding-top: 20px;
    padding-left: 0px;
  }
}
