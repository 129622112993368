
  .footer__block {
    background-color: var(--tertiory-color);
    padding: 30px 15px;
  }
  .inner__footer__block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .footer__left__side,
  .footer__right__side {
    display: flex;
    align-items: flex-end;
  }
  .footer__logo {
    margin: 0px 15px 5px 0px;
  
  }
  .footer__link{
      color: var(--primary-color);
      margin: 0px 0px 0px 15px;
  }
  .footer__contact__link {
    margin: 0px 0px 0px 15px;
  }
  .footer__contact__link a {
    color: black;
  }
  
  @media only screen and (max-width: 1200px) {
    .inner__footer__block {
      flex-direction: column;
      align-items: flex-start;
    }
    .footer__left__side   {
      margin-bottom: 15px;
    }
    .footer__link__block{
      margin: 15px 0px 0px;
  }
  
  .footer__contact__link{
      margin: 0px 15px 0px 0px;
  }
  }
  @media only screen and (max-width: 992px) {
    .inner__footer__block {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer__left__side,
    .footer__right__side {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
    }
    .footer__link__block{
        margin: 15px 0px 0px 0px;
    }
    .footer__link{
        margin:0px 15px 0px 0px;
    }
    .footer__contact__link{
        margin: 0px 15px 0px 0px;
    }
  }
  @media only screen and (max-width: 768px) {
  .footer__link__block{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
  }
  }
  
  .copyright__block {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
  }
  .copright__text {
    font-size: 14px;
    margin: 0px;
  }
  .empty__copyright__text{
      display: block;
  }
  @media only screen and (max-width: 568px) {
      .copyright__block{
          display: block;
          position: relative;
          min-height: 60px;
      }
      .copright__text{
          position: absolute;
          left: 15px;
          bottom: 10px;
      }
      .empty__copyright__text{
          display: none;
      }
      }