.homepage__product__card__image__block {
  margin-bottom: 20px;
}
.homepage__product__card__image__block:hover{
  text-decoration: none !important;
  color: red;
}
.homepage__product__card__image {
  width: 350px;
  height: 194px;
  box-shadow: 0px 0px 20px 0px #88888850;
}
.homepage__product__card__image__block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.homepage__product__card__title__block {
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.inner__homepage__product__card__title__block {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 10px 0px;
  box-shadow: 0px 0px 20px 0px #88888820;
}

.homepage__product__card__title {
  /* text-decoration: none; */
  width: 80%;
  text-align: center;
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
}

@media only screen and (max-width: 991px) {
  .homepage__product__card {
    width: 280px;
  }
  .homepage__product__card__title__block {
    width: 280px;
  }
  .homepage__product__card__image {
    width: 280px;
    height: 155px;
  }
  .homepage__product__card__title{
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .homepage__product__card__image__block {
    margin: 0px !important;
  }
  .homepage__product__card {
    width: 240px !important;
  }
  .homepage__product__card__title__block {
    width: 240px !important;
  }
  .homepage__product__card__image {
    width: 240px !important;
    height: 133px !important;
  }
  .homepage__product__card__title{
    font-size: 14px;
  }
}

@media only screen and (max-width: 578px) {
  
  .homepage__product__card {
    width: 200px !important;
    /* overflow: hidden; */
  }
  .homepage__product__card__title__block {
    width: 200px !important;
  }
  .inner__homepage__product__card__title__block{
    width: 100%;
  }
  .homepage__product__card__image {
    width: 200px !important;
    height: 111px !important;
  }
  .homepage__product__card__title{
    font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  .homepage__product__card {
    width: 160px !important;
    overflow: hidden;
  }
  .inner__homepage__product__card__title__block{
    width: 160px !important;
  }
  .homepage__product__card__title__block {
    width: 160px !important;
  }
  .homepage__product__card__image {
    width: 160px !important;
    height: 89px !important;
  }
  .homepage__product__card__title{
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .homepage__product__card {
    width: 140px !important;
    overflow: hidden;
  }
  .inner__homepage__product__card__title__block{
    width: 140px !important;
  }
  .homepage__product__card__title__block {
    width: 140px !important;
  }
  .homepage__product__card__image {
    width: 140px !important;
    height: 78px !important;
  }
  .homepage__product__card__title{
    font-size: 12px;
  }
}
@media only screen and (max-width: 340px) {
  
  .homepage__product__card {
    width: 120px !important;
    overflow: hidden;
  }
  .inner__homepage__product__card__title__block{
    width: 120px !important;
  }
  .homepage__product__card__title__block {
    width: 120px !important;
  }
  .homepage__product__card__image {
    width: 120px !important;
    height: 66px !important;
  }
}
