.main__top__navbar {
  background-color: var(--primary-color);
}
.top__navbar {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.topnavbar__contact__tab__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topnavbar__contact__tab {
  color: white;
  margin: 0px 0px 0px 20px;
}
.topnavbar__contact__tab a {
  color: white;
}
.topnavbar__tab__icon {
  margin-right: 8px;
}
@media only screen and (max-width: 768px) {
  .top__navbar {
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 0px 20px;
  }

  .topnavbar__contact__tab__block {
    margin-top: 20px;
    width: 100%;
    /* margin:; */
  }
  .topnavbar__contact__tab {
    margin: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .top__navbar {
    padding: 20px 0px 10px;
  }
  .topnavbar__contact__tab {
    font-size: 14px;
    margin: 0px 15px 0px 0px;
  }
  .topnavbar__tab__icon {
    width: 18px !important;
    margin: 0px 5px 2px 0px !important;
  }
  .topnavbar__contact__tab {
    font-size: 14px !important;
  }
  .topnavbar__tab__text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 475px) {
  .top__navbar {
    padding: 20px 0px 10px;
  }
  .topnavbar__contact__tab {
    font-size: 14px;
    margin: 0px 10px 0px 0px;
  }
  .topnavbar__tab__icon {
    width: 14px !important;
    margin: 0px 5px 2px 0px !important;
  }
  .topnavbar__contact__tab {
    font-size: 11px !important;
  }
  .topnavbar__tab__text {
    font-size: 11px;
  }
  /* .topnavbar__tab__icon{
        display: none;
    } */
}
@media only screen and (max-width: 404px) {

  .topnavbar__contact__tab {
    font-size: 14px;
    margin: 0px 8px 0px 0px !important;
  }
  
  /* .topnavbar__tab__icon{
        display: none;
    } */
}
